import * as React from "react";

function SvgComponent({ className }) {
  return (
    <svg
      width={40.178}
      height={27.99}
      viewBox="0 0 40.178 27.99"
      className={className}
    >
      <defs>
        <style>
          {
            ".prefix__a{fill:none;stroke:#35353d;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.1px}"
          }
        </style>
      </defs>
      <path
        className="prefix__a"
        d="M28.623 10.78l-1.5 1.37a3.228 3.228 0 01-2.179.844h-9.332a3.218 3.218 0 01-2.177-.844l-1.5-1.37M34.338 20.26h-6.107v-1.075a2.177 2.177 0 011.863-2.153l4.244-.585M5.841 16.443l4.246.585a2.177 2.177 0 011.863 2.153v1.075H5.842M2.55 13.851v10.123"
      />
      <path
        className="prefix__a"
        d="M2.551 13.851L.865 12.02a.987.987 0 01-.315-.72v-2a.986.986 0 01.985-.985h3.846L8.71 1.931A3.5 3.5 0 0111.494.547h17.188a3.493 3.493 0 012.784 1.384l3.329 6.384h3.848a.986.986 0 01.984.985v2a.985.985 0 01-.313.72l-1.687 1.831v10.124"
      />
      <path
        className="prefix__a"
        d="M31.452 23.974v.377a3.088 3.088 0 003.09 3.089 3.089 3.089 0 003.09-3.089v-.377M8.732 23.974v.377a3.09 3.09 0 01-3.091 3.089 3.09 3.09 0 01-3.09-3.089v-.377M34.336 23.975H5.843M17.656 20.255h4.866"
      />
      <path
        d="M28.682 3.091H11.494a.96.96 0 00-.661.269L8.247 8.318h23.679L29.34 3.36a.957.957 0 00-.658-.269z"
        fill="#ff5876"
      />
    </svg>
  );
}

export default SvgComponent;
