import React from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import Car from "components/icon/Car";
import Link from "next/link";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    padding: "16px",
    margin: "70px auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "600px",
    width: "100%"
  },
  cardContent: {
    alignItems: "center",
    backgroundColor: "#F8F8F9",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "24px",
    position: "relative",
    width: "100%",
    padding: "64px 20px 40px"
  },
  iconcardContainer: {
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    display: "flex",
    height: "62px",
    justifyContent: "center",
    left: "50%",
    position: "absolute",
    right: "50%",
    top: "-30px",
    transform: "translateX(-50%)",
    width: "62px",
    "& svg": {
      width: "40px",
      height: "40px",
      fill: theme.palette.primary.dark
    }
  },
  cardTitle: {
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: "10px"
  },
  cardDescription: {
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center"
  },
  [theme.breakpoints.up("md")]: {
    cardContainer: {
      margin: "90px auto",
      border: "1px solid #E9ECEE",
      borderRadius: "8px",
      padding: "36px 60px"
    },
    iconcardContainer: {
      height: "70px",
      width: "70px"
    },
    cardTitle: { fontSize: "18px" }
  }
}));

const ErrorCard = ({ href, action, Icon = Car, title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <div className={classes.iconcardContainer}>
          <Icon />
        </div>
        <Typography variant="h1" className={classes.cardTitle}>
          {title}
        </Typography>
        <p className={classes.cardDescription}>{description}</p>
      </div>
      <Link href={href} passHref>
        <Button
          id="lot_finished_button"
          color="primary"
          variant="contained"
          fullWidth
        >
          {action}
        </Button>
      </Link>
    </div>
  );
};

export default ErrorCard;
